import React from 'react';
import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../styles/variables'

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1150px;
  height: 150px;
  background: ${colors.gray.ligh};
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 73px;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
    height: 90px;
    maring-top: 0px;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100vw;
    margin: 0;
  }
`

const Header = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: ${colors.black};

  @media ${`(max-width: ${breakpoints.md}px)`} {
    font-size: 20px;
    line-height: 24px;
  }
`

interface Props {
  title: string,
}

const PageHeader: React.FC<Props> = (props) => {

  return (
    <HeaderContainer>
      <Header>{props.title}</Header>
    </HeaderContainer>
  )
}

export default PageHeader;
