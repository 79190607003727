import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import ProgramList from './ProgramList'
import { colors, breakpoints, fonts } from '../../styles/variables'

const ProgramMapContainer = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  display: inline-grid;
  grid-template-columns: repeat(3, 25%);
  column-gap: 30px;
  align-items: start;
  justify-content: center;
  padding-top: 50px;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    maring-top: 0px;
  }
`

interface ProgramProps {
  programs: [],
  selectedDay: Date,
  selectDay: (index: number) => void,
  firstDay: Date,
  lastDay: Date
}

const ProgramMap: React.FC<ProgramProps> = (props) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.xl });
  const [groupedData, setGroupedData] = useState([])
  const [previousDay, setPreviousdDay] = useState(new Date())
  const [nextDay, setNextDay] = useState(new Date())
  const [days, setDays] = useState([])
  const [fetched, setFetched] = useState(false)
  const current = new Date()

  const filterRangeData = () => {
    // Get three range data and add startTime and Day to array
    return props.programs.filter((item, index) => {
      const date = new Date(item.start)
      const isInRange = days.find(day => day.getDate() === date.getDate())
      if (isInRange) {
        const isPast = date.getTime() < current.getTime()
        const isCurrent = isPast && current.getTime() < new Date(props.programs[index + 1].start).getTime()

        item.startTime = date.getTime()
        item.day = date.getDate()
        item.isPast = isPast
        item.isCurrent = isCurrent
        return item
      }
    })
  }

  useEffect(() => {
    // gourp objects by day
    setFetched(false)
    const rangeData = filterRangeData()
    const uniqueData = [...new Map(rangeData.map(item => [item.startTime, item])).values()]

    const groupedProgrmas = uniqueData.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj['day']
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
    setFetched(true)
    setGroupedData(groupedProgrmas)
  }, [days])

  useEffect(() => {
    const index = props.selectedDay.getDate()
    previousDay.setDate(index - 1)
    nextDay.setDate(index + 1)
    setPreviousdDay(new Date(previousDay))
    setNextDay(new Date(nextDay))

    if (isMobile) {
      setDays([props.selectedDay])
    } else {
      if (index === props.firstDay.getDate()) {
        setDays([props.selectedDay, nextDay])
      } else if (index === props.lastDay.getDate()) {
        setDays([previousDay, props.selectedDay])
      } else {
        setDays([new Date(previousDay), props.selectedDay, new Date(nextDay)])
      }
    }

  }, [props.selectedDay, props.programs, isMobile])

  return (
    fetched && <ProgramMapContainer>
      {Object.entries(groupedData).map((item, index) => {
        let opacity = 1
        if (index !== 1) {
          opacity = 0.35
        }
        if (index === 0 && days[index].getDate() === props.firstDay.getDate() && days.length === 2) {
          opacity = 1
        }
        if (index === 1 && days[0].getDate() === props.firstDay.getDate() && days.length === 2) {
          opacity = 0.35
        }
        if (isMobile) {
          opacity = 1
        }
        return (
          <ProgramList
            key={index}
            data={item[1]}
            date={days[index]}
            index={index}
            setDate={props.selectDay}
            opacity={opacity}
            selectedDay={props.selectedDay}
            isFirst={index === 0 && days.length == 2}
          />
        )
      }


      )}
    </ProgramMapContainer>
  )
}

export { ProgramMap };
