import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { getMediaSeriesByName } from '../../services/api'
import Dots from '../../resources/icons/dots.svg'
import { colors, breakpoints, fonts } from '../../styles/variables'

const ProgramListContainer = styled.div.attrs((props: { opacity: number, zIndex: number, disabled: boolean, isFirst: boolean, index: number }) => props)`
  width: 100%;
  display: flex:
  flex-direction: column;
  justify-content: center;
  opacity: ${props => props.opacity};
  cursor: pointer;
  z-index: ${props => props.zIndex};
  grid-column-start: ${props => (props.isFirst ? props.index + 2 : 'auto')};

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    align-items: center;
    align-content: center;
  }
`

const Header = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${colors.black};
  height: 20px;
`
const DateContainer = styled.div`
  font-family: font-family: ${fonts.ptMono};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: ${colors.black};
`
const ListRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 15% 85%;
  column-gap: 5px;
  justify-content: start;
  align-items: center;
  margin: 5px 0;
  backgorund: none;
  border: none;
  z-index: 10;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    grid-template-columns: 10% 60%;
    justify-content: center;

    font-size: 16px;
line-height: 18px;
  }
`

const Content = styled.div.attrs((props: { border: boolean, paddingLeft: number, isCurrent: boolean, decoration: string }) => props)`
  display: flex;
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.black};
  border: ${(props) => (props.border ? `1px solid ${props.isCurrent ? colors.red : colors.gray.silver}` : 'none')};
  box-sizing: border-box;
  border-radius: 4px;
  height: 29px;
  text-align: center;
  align-items: center;
  padding-left:  ${props => (props.paddingLeft)}px;
  text-decoration: ${props => (props.decoration || 'none')};

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    padding-left: ${props => (props.paddingLeft - 5)}px;
  }
`


interface ProgramProps {
  data: any[],
  date: Date,
  index: number,
  setDate: (index: number) => void,
  opacity: number,
  isFirst: boolean,
  selectedDay: Date,
}

const ProgramList = (props: ProgramProps) => {
  const [showPast, setShowPast] = useState(false)
  const [showHidden, setShowHidden] = useState(false)
  const current = new Date()
  const days = props.data.map(item => item.start)
  const isCurrent = days.every(day => new Date(day).getDate() === current.getDate())

  const renderStartTime = (time) => {
    const start = new Date(time)
    const hours = start.getHours()
    const minutes = start.getMinutes()

    return `${hours < 10 ? `0${hours}` : `${hours}`}.${minutes < 10 ? `0${minutes}` : `${minutes}`}`
  }



  const renderHiddenContents = (contents) => {
    if (showHidden) {
      return (contents.map((item, index) => (
        <ListRow key={`${item.name}-${index}`} onClick={() => navigateTo(item.name)}>
          <Content border={false} paddingLeft={0}>{renderStartTime(item.startTime)}</Content>
          <Content border={true} paddingLeft={10}>{item.name}</Content>
        </ListRow>
      )))
    }
    else {
      return (
        <ListRow key={`open-hidden-programs`}>
          <Content border={false} paddingLeft={15}><Dots /></Content>
          <Content border={false} paddingLeft={20} decoration={'underline'} onClick={() => setShowHidden(true)}>Näytä lisää päivän ohjelmia</Content>
        </ListRow>
      )
    }
  }

  const renderPastContents = (contents) => {
    if (showPast) {
      return (contents.map((item, index) => (
        <ListRow key={`${item.name}-${index}`} onClick={() => navigateTo(item.name)}>
          <Content border={false} paddingLeft={0}>{renderStartTime(item.startTime)}</Content>
          <Content border={true} paddingLeft={10}>{item.name}</Content>
        </ListRow>
      )))
    }
    else {
      return (
        <ListRow key={`open-past-programs`}>
          <Content border={false} paddingLeft={15}><Dots /></Content>
          <Content border={false} paddingLeft={20} decoration={'underline'} onClick={() => setShowPast(true)}>Näytä päivän menneet ohjelmat</Content>
        </ListRow>
      )
    }
  }

  const renderContent = () => {
    let data = []
    let pastPrograms = []
    let futurePrograms = []
    if (isCurrent) {
      data = props.data.filter(item => !item.isPast || item.isCurrent).slice(0, 11)
      pastPrograms = props.data.filter(item => item.isPast && !item.isCurrent && item.day === current.getDate())
      futurePrograms = props.data.filter(item => !item.isPast || item.isCurrent).slice(11)
    } else {
      data = props.data.slice(0, 11)
      futurePrograms = props.data.slice(11)
    }


    return (
      <div style={{ marginTop: isCurrent ? 0 : 29 }}>
        { isCurrent && renderPastContents(pastPrograms)}
        {data.splice(0, 11).map((item, index) => (
          <ListRow key={`${item.name}-${index}`} onClick={() => navigateTo(item.name)}>
            <Content border={false} paddingLeft={0}>{renderStartTime(item.startTime)}</Content>
            <Content border={true} paddingLeft={10} isCurrent={item.isCurrent}>{item.name}</Content>
          </ListRow>
        )
        )}
        { futurePrograms.length > 0 && renderHiddenContents(futurePrograms)}
      </div>
    )
  }

  const parseDate = () => {
    return `${props.date?.getDate()}.${props.date?.getMonth() + 1}.${props.date?.getFullYear()}`
  }

  const parseHeader = () => {
    if (isCurrent) return "Tänään"
    if (props.date && current.getDate() - props.date.getDate() === -1) return "Huomenna"
    if (props.date && current.getDate() - props.date.getDate() === 1) return "Eilen"
  }

  const navigateTo = async (name: string) => {
    const mediaSeries = await getMediaSeriesByName(name)
    if (mediaSeries.data) {
      navigate(`/ohjelmasarja/${mediaSeries.data.id}`);
    }
  }

  return (
    <ProgramListContainer opacity={props.opacity} isFirst={(isCurrent && props.isFirst) || props.isFirst} index={props.index}>
      <Header>{parseHeader()}</Header>
      <DateContainer>{parseDate()}</DateContainer>
      {renderContent()}
    </ProgramListContainer>
  )
}

export default ProgramList
