import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import Dropdown, { Option } from 'react-dropdown';
import 'react-dropdown/style.css';
import { useMst } from '../../models/Root'
import styled from 'styled-components'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { colors, fonts } from '../../styles/variables'

require('./LCS.css');

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 336px;
  margin: 0 auto;
  justify-content: center;
  cursor: pointer;
`

const DropDownHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.4em 3em 0.4em 2em;
  margin-bottom: 3px;
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${colors.white};
  background: ${colors.red};
  border-radius: 100px;
  height: 50px;
`
const DropDownListContainer = styled.div`

`
const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: ${colors.red};
  box-sizing: border-box;
  color: ${colors.white};
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border-radius: 10px;
  &:first-child {
    padding-top: 0.8em;
  }
  &:last-child {
    padding-bottom: 0.3em;
  }
`
const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;
  cursor: pointer;
`

// cursor: pointer;
// @ts-ignore
interface SelectionProps {
  value: any;
  setValue: (newSelection: any) => void;
}

const LiveChannelSelection: React.FC<SelectionProps> = observer(props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const dropdownSelectionChanged = (newSelection) => {
    props.setValue({ value: newSelection.value, label: newSelection.label.toString() })
    toggleOpen()
  }

  const options = [
    { value: 0, label: 'Radio Dei - Pääkanava' },
    { value: 1, label: 'Helsinki' },
    { value: 2, label: 'Kajaani' },
    { value: 3, label: 'Kemi' },
    { value: 4, label: 'Kokkola' },
    { value: 5, label: 'Kristiinankaupunki' },
    { value: 6, label: 'Lahti' },
    { value: 7, label: 'Oulu' },
    { value: 8, label: 'Pori' },
    { value: 9, label: 'Rovaniemi' },
    { value: 10, label: 'Seinäjoki' },
    { value: 11, label: 'Turku' },

  ];



  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggleOpen}>{props.value.label}{isOpen ? <ExpandLess /> : <ExpandMore />}</DropDownHeader>

      {isOpen &&
        <DropDownListContainer>
          <DropDownList>
            {options.map(option => (
              <ListItem key={option.value} onClick={() => dropdownSelectionChanged(option)} >{option.label}</ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      }
    </DropDownContainer>
  )
});

export { LiveChannelSelection };
