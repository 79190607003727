import * as React from 'react'
import styled from '@emotion/styled'

import { dimensions, breakpoints } from '../styles/variables'
import { useMediaQuery } from 'react-responsive'

const StyledPage = styled.div`
  display: block;
  flex: 1;
  position: relative;
  //padding-left: ${dimensions.containerPadding}rem;
  //padding-right: ${dimensions.containerPadding}rem;
  //padding-top: ${dimensions.containerPaddingVertical}rem;
  margin-bottom: 3rem;

  @media ${`(min-width: 1446px)`} {
    //padding-left: ${dimensions.containerPaddingXl}rem;
    //padding-right: ${dimensions.containerPaddingXl}rem;
  }

  @media ${`(min-width: ${breakpoints.xxxl}px)`} {
    //padding-left: ${dimensions.containerPaddingHuge}rem;
    //padding-right: ${dimensions.containerPaddingHuge}rem;
  }
`
const StyledMobilePage = styled.div`
  display: block;
  flex: 1;
  position: relative;
  margin-bottom: 3rem;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    margin: 0;
  }
`

interface PageProps {
  className?: string
}

// @ts-ignore
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1280 })
  return isDesktop ? children : null
}

// @ts-ignore
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1279 })
  return isMobile ? children : null
}

const Page: React.FC<PageProps> = ({ children, className }) => (
  <>
    <Desktop>
      <StyledPage className={className}>{children}</StyledPage>
    </Desktop>
    <Mobile>
      <StyledMobilePage className={className}>{children}</StyledMobilePage>
    </Mobile>
  </>
)

export default Page
