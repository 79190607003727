import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Page from '../components/Page'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import { LiveChannelSelection } from '../components/LiveChannelSelection/LiveChannelSelection'
import DatePicker from '../components/DatePicker'
import { ProgramMap } from '../components/ProgramMap'
import { getProgrammCalendar } from '../services/api'
import { breakpoints, colors, fonts } from '../styles/variables'

const ChannelSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`

const ChannelSelectionText = styled.div`
  font-family:${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${colors.black};
`

const SeriesMapPage = () => {
  const [selectedChannel, setSelectedChannel] = useState({ value: '1', label: 'Radio Dei - Pääkanava' })
  const [programFetched, setProgramFetched] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [week, setWeek] = useState(0)
  const [firstDay, setFirstDay] = useState(new Date())
  const [lastDay, setLastDay] = useState(new Date())
  const [apiResult, setApiResult] = useState([])

  useEffect(() => {
    const previousMonday = firstDay.setDate(firstDay.getDate() - (firstDay.getDay() + 6) % 7);
    const nextWeekSunday = lastDay.setDate(lastDay.getDate() + ((7 - lastDay.getDay()) % 7) + 7);

    setFirstDay(new Date(previousMonday))
    setLastDay(new Date(nextWeekSunday))

    if (!programFetched) {
      for (let i = 0; i < 3; i++) {
        let arr = []
        getProgrammCalendar(i).then(res => {
          res.data.forEach(item => {
            const date = new Date(item.start)
            if (date.getDate() >= new Date(previousMonday).getDate() &&
              date.getDate() <= new Date(nextWeekSunday).getDate()) {
              const userTimezoneOffset = date.getTimezoneOffset() * 60000;

              arr.push({
                name: item.name,
                description: item.description,
                start: new Date(date.getTime() + userTimezoneOffset)
              })
            }
          })
        }).finally(() => {
          setApiResult(prev => prev.concat(arr))
        })
      }
      setProgramFetched(true)
    }
  }, [])

  const setSelection = (newValue: any) => {
    setSelectedChannel(newValue)
  }

  const setSelected = (newDay: any) => {
    const dayToSet = newDay === 0 ? 7 : newDay
    let distance = 0;

    if (selectedDay.getDay() === 0) {
      distance = newDay === 1 ? (dayToSet - 7) : (dayToSet - 7) % 7
    } else {
      distance = newDay === 1 ? (dayToSet - selectedDay.getDay()) : (dayToSet - selectedDay.getDay()) % 7
    }

    const newDate = selectedDay.setDate(selectedDay.getDate() + distance)

    setSelectedDay(new Date(newDate))
  }

  const setDay = (indexOfDay: number) => {
    const index = indexOfDay === 0 ? -1 : 1
    const newDate = selectedDay.setDate(selectedDay.getDate() + index)
    setSelectedDay(new Date(newDate))
  }

  const setSelectedWeek = () => {
    if (week === 0) {
      const newDate = selectedDay.setDate(firstDay.getDate() + 7)
      setWeek(1)
      setSelectedDay(new Date(newDate))
    } else {
      const newDate = selectedDay.setDate(firstDay.getDate())
      setWeek(0)
      setSelectedDay(new Date(newDate))
    }
  }

  return (
    <Page>
      <Container>
        <PageHeader title="Ohjelmakartta" />
        <ChannelSelectionContainer>
          <ChannelSelectionText>Valitse kanava</ChannelSelectionText>
          <LiveChannelSelection value={selectedChannel} setValue={setSelection} />
        </ChannelSelectionContainer>
        <DatePicker date={selectedDay} setDate={(value) => setSelected(value)} week={week} setWeek={setSelectedWeek} />
        {programFetched && apiResult.length > 0 && <ProgramMap programs={apiResult} selectedDay={selectedDay} selectDay={(index) => setDay(index)} firstDay={firstDay} lastDay={lastDay} />}
      </Container>
    </Page>
  )
}

export default SeriesMapPage
