import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive'
import Arrow from '../resources/icons/arrow-next.svg'
import { colors, breakpoints, fonts } from '../styles/variables'

const DatePickerContainer = styled.div`
  width: 100%;
  max-width: 1150px;
  height: 40px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(8, auto);
  align-items: center;
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
    maring-top: 0px;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100vw;
    margin: 0;
  }
`

const DateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid ${colors.white};
`

const Date = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: ${colors.white};
  text-align: center;
`

const WeekContainer = styled.div.attrs((props: { rotate: number }) => props)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.black};
  border: 0.5px solid ${colors.white};
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: ${colors.white};
  text-align: center;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    transform: rotate(${props => props.rotate || 0}deg);
  }
`

interface DateProps {
  date: Date,
  setDate: (selectedDay) => void,
  week: number,
  setWeek: () => void;
}

const DatePicker: React.FC<DateProps> = (props) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });
  const days = [
    { dayIndex: 1, dayDektop: 'Maanantai', dayMobile: 'Ma' },
    { dayIndex: 2, dayDektop: 'Tiistai', dayMobile: 'Ti' },
    { dayIndex: 3, dayDektop: 'Keskiviikko', dayMobile: 'Ke' },
    { dayIndex: 4, dayDektop: 'Torstai', dayMobile: 'To' },
    { dayIndex: 5, dayDektop: 'Perjantai', dayMobile: 'Pe' },
    { dayIndex: 6, dayDektop: 'Lauantai', dayMobile: 'La' },
    { dayIndex: 0, dayDektop: 'Sunnuntai', dayMobile: 'Su' },
  ]

  return (
    <DatePickerContainer>
      {props.week === 1 && <WeekContainer onClick={() => props.setWeek()} rotate={180} >{isMobile ? <Arrow /> : 'Edellinen vk'}</WeekContainer>}
      { days.map((day) => {
        return (
          <DateContainer
            key={day.dayIndex}
            style={{ background: props.date.getDay() === day.dayIndex ? `${colors.red}` : `${colors.black}` }}
            onClick={() => props.setDate(day.dayIndex)}
          >
            <Date>{isMobile ? day.dayMobile : day.dayDektop}</Date>
          </DateContainer>
        )
      })}
      {props.week === 0 && <WeekContainer onClick={() => props.setWeek()}>{isMobile ? <Arrow /> : 'Seuraava vk'}</WeekContainer>}
    </DatePickerContainer>
  )
}

export default DatePicker;
